import type { ContextProps, ContextServices } from '../../../types';
import { fetchViewedAndCurrentMember } from '../viewer-controller';
import { handleNavigation } from './navigation';
import {
  addInitialDataForWidgetPlugins,
  emitMemberChangedEventToPlugins,
} from './widget-plugins';

export const addLoginHandler = (
  contextProps: ContextProps,
  contextServices: ContextServices,
) => {
  return contextProps.wixCodeApi.user.onLogin(async () => {
    await fetchViewedAndCurrentMember(contextProps, contextServices);
    addInitialDataForWidgetPlugins(contextProps, contextServices);
    await emitMemberChangedEventToPlugins(contextProps, contextServices);
    await contextServices.multiStateBoxService.showMainAppState();
    await handleNavigation(contextProps, contextServices);

    return contextServices.menuService.restoreMenuItems(contextProps.state);
  });
};
