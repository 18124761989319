import { membersAreaMenuItemsClick } from '@wix/bi-logger-members-app-uou/v2';
import { getPageIdAndAppDefIdFromWidgetId } from '@wix/members-area-app-definitions';

import type {
  FlowAPI,
  MultiStateBoxService,
  RouteData,
  SettingsPageContextProps,
  SettingsPageContextServices,
  SettingsPageErrorStateService,
  SettingsPageState,
  WixCodeApi,
} from '../../../types';
import { emitTabChangedEventToPlugins } from './widget-plugins';

type NavigationRulesProps = Pick<SettingsPageState, 'currentMember'> & {
  routeData: RouteData;
  isEditor: boolean;
};

export const redirectToLogin = async (
  wixCodeApi: WixCodeApi,
  multiStateBoxService: MultiStateBoxService,
  errorStateService: SettingsPageErrorStateService,
) => {
  await multiStateBoxService.showErrorAppState();
  errorStateService.setToNotFoundContent();

  // Does not work in SSR, renders login in CSR
  // Do not add await, it will stop execution of the rest of the code
  wixCodeApi.user.promptLogin({});
};

export const redirectTo404 = (
  multiStateBoxService: MultiStateBoxService,
  errorStateService: SettingsPageErrorStateService,
) => {
  multiStateBoxService.showErrorAppState();
  errorStateService.setToNotFoundContent();
};

const getNavigationRules = ({
  currentMember,
  routeData,
  isEditor,
}: NavigationRulesProps) => {
  const { stateId } = routeData;

  const isRouteDataMissing = !stateId;

  const shouldRedirectToLogin =
    !isEditor && !isRouteDataMissing && !currentMember;

  const shouldRedirectTo404 = !isEditor && isRouteDataMissing;

  return { shouldRedirectToLogin, shouldRedirectTo404 };
};

const emitMembersAreaMenuItemClickBIEvent = (
  flowAPI: FlowAPI,
  routeData: RouteData,
) => {
  const { pageId, appDefId } = getPageIdAndAppDefIdFromWidgetId(
    routeData.visibleWidgetId,
  );

  flowAPI.bi?.report(
    membersAreaMenuItemsClick({
      pageName: pageId,
      originAppId: appDefId,
    }),
  );
};

const showTab = async (
  { flowAPI }: Pick<SettingsPageContextProps, 'flowAPI'>,
  {
    multiStateBoxService,
    routeDataService,
    widgetPluginHostService,
  }: Pick<
    SettingsPageContextServices,
    'multiStateBoxService' | 'routeDataService' | 'widgetPluginHostService'
  >,
) => {
  const routeData = routeDataService.getRouteData();

  // During installation, widget data is not yet available
  if (!routeData.stateId) {
    return;
  }

  await widgetPluginHostService.membersAreaWidgetReady();
  await multiStateBoxService.showSlotStateById(routeData.stateId);

  await emitTabChangedEventToPlugins({
    widgetPluginHostService,
    routeDataService,
  });

  emitMembersAreaMenuItemClickBIEvent(flowAPI, routeData);
};

export const handleNavigation = (
  {
    wixCodeApi,
    state,
    flowAPI,
  }: Pick<SettingsPageContextProps, 'wixCodeApi' | 'state' | 'flowAPI'>,
  {
    multiStateBoxService,
    errorStateService,
    routeDataService,
    menuService,
    widgetPluginHostService,
  }: Pick<
    SettingsPageContextServices,
    | 'multiStateBoxService'
    | 'errorStateService'
    | 'menuService'
    | 'routeDataService'
    | 'widgetPluginHostService'
  >,
) => {
  const routeData = routeDataService.getRouteData();
  const { shouldRedirectToLogin, shouldRedirectTo404 } = getNavigationRules({
    routeData,
    currentMember: state.currentMember,
    isEditor: flowAPI.environment.isEditor,
  });

  if (shouldRedirectToLogin) {
    return redirectToLogin(wixCodeApi, multiStateBoxService, errorStateService);
  }

  if (shouldRedirectTo404) {
    return redirectTo404(multiStateBoxService, errorStateService);
  }

  menuService.selectMenuItem(routeData.path);

  return showTab(
    { flowAPI },
    { widgetPluginHostService, routeDataService, multiStateBoxService },
  );
};
