import model from './model';
import { pageReady } from './viewer-controller';
import { getContext } from './viewer-controller/context';

export default model.createController((props) => {
  return {
    pageReady: async () => {
      const { contextProps, contextServices } = getContext(props);

      return contextServices.monitoringService.toMonitored(
        'settings-page.controller.page_ready',
        () => pageReady(contextProps, contextServices),
      );
    },
    exports: {},
  };
});
