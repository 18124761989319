import { ViewerPublicApiError } from '../../constants';
import type { Callback, PublicApiContext, ViewerPublicAPI } from '../../types';

export class PublicAPI implements ViewerPublicAPI {
  state = this.context.state;
  menuService = this.context.menuService;
  errorStateService = this.context.errorStateService;
  multiStateBoxService = this.context.multiStateBoxService;

  constructor(private readonly context: PublicApiContext) {}

  getViewedUser(onSuccess?: Callback, onError?: Callback) {
    const { viewedMember } = this.state.members;
    if (!viewedMember) {
      onError?.(ViewerPublicApiError.MissingViewedMember);
      throw new Error(ViewerPublicApiError.MissingViewedMember);
    }

    const viewedMemberId = viewedMember.id!;

    onSuccess?.(viewedMemberId);
    return Promise.resolve(viewedMemberId);
  }

  enterPublicProfilePreviewMode() {
    this.menuService.filterMenuItemsForViewingUser(false);
    return Promise.resolve();
  }

  leavePublicProfilePreviewMode() {
    this.menuService.restoreMenuItems(this.state);
    return Promise.resolve();
  }

  async openBlockedMemberEmptyState() {
    const { viewedMember } = this.state.members;
    await this.multiStateBoxService.showErrorAppState();
    await this.errorStateService.setUserIsBlockedContent(
      viewedMember?.profile?.nickname ?? '',
    );
  }

  clearMenus() {
    this.menuService.clearMenuItems();
    return Promise.resolve();
  }
}
