import {
  followMember,
  unfollowMember,
} from '@wix/ambassador-members-v3-follow/http';
import { withResolvedError } from '@wix/members-area-commons-ts';
import type { IHttpClient } from '@wix/yoshi-flow-editor';

import type { ErrorHandler } from '../../../types';
import type { FollowersWriteService as IFollowersWriteService } from '../../../types/followers';

export class FollowersWriteService implements IFollowersWriteService {
  constructor(
    private readonly httpClient: IHttpClient,
    private readonly errorHandler: ErrorHandler,
  ) {}

  async follow(memberId: string) {
    const requestFn = () => this.httpClient.request(followMember({ memberId }));

    await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });
  }

  async unfollow(memberId: string) {
    const requestFn = () =>
      this.httpClient.request(unfollowMember({ memberId }));

    await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });
  }
}
