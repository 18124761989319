import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import type {
  ErrorStateService as IErrorStateService,
  TFunction,
  W,
} from '../../../types';
import type { MembersAreaPublicAPI } from '../../../types/services';

export class ErrorStateService implements IErrorStateService {
  constructor(
    private readonly $w: W,
    private readonly membersAreaPublicAPI: MembersAreaPublicAPI,
    private readonly t: TFunction,
  ) {}

  resetToNotFoundContent() {
    this.$w('#text1').text = this.t('app.empty-state.not-found.title');
    this.$w('#text2').text = this.t('app.empty-state.not-found.description');
    this.$w('#homeButton').label = this.t('app.empty-state.not-found.button');
    this.$w('#homeButton').link = '/';
  }

  async setUserIsBlockedContent(memberName: string) {
    const openBlockedMembersModalQuery = '?blockedMembersModal=true';
    const myAccountPath = await this.membersAreaPublicAPI.getSectionUrl({
      appDefinitionId: MA_APP_IDS.MY_ACCOUNT.appDefinitionId,
      sectionId: MA_APP_IDS.MY_ACCOUNT.pageId,
      widgetId: MA_APP_IDS.MY_ACCOUNT.widgetId,
    });

    this.$w('#text1').text = this.t('app.empty-state.user-is-blocked.title', {
      memberName,
    });
    this.$w('#text2').text = this.t(
      'app.empty-state.user-is-blocked.description',
    );
    this.$w('#homeButton').label = this.t(
      'app.empty-state.user-is-blocked.button',
    );
    this.$w('#homeButton').link = myAccountPath + openBlockedMembersModalQuery;
  }
}
