import type {
  SettingsPageContextProps,
  SettingsPageContextServices,
} from '../../../types';
import { fetchCurrentMember } from '../viewer-controller/state';
import { handleNavigation, redirectTo404 } from './navigation';
import {
  addInitialDataForWidgetPlugins,
  emitMemberChangedEventToPlugins,
} from './widget-plugins';

export const addLocationChangeHandler = (
  contextProps: SettingsPageContextProps,
  contextServices: SettingsPageContextServices,
) => {
  return contextProps.wixCodeApi.location.onChange(async () => {
    const { stateId } = contextServices.routeDataService.getRouteData();

    const { hasMemberChanged } = await fetchCurrentMember(
      contextProps,
      contextServices,
    );

    if (!stateId) {
      return redirectTo404(
        contextServices.multiStateBoxService,
        contextServices.errorStateService,
      );
    }

    if (hasMemberChanged) {
      addInitialDataForWidgetPlugins(contextProps, contextServices);
      await emitMemberChangedEventToPlugins(contextProps, contextServices);
    }

    await contextServices.widgetPluginHostService.membersAreaWidgetReady();
    await contextServices.multiStateBoxService.showMainAppState();

    return handleNavigation(contextProps, contextServices);
  });
};
