import type { IntegrationApplication } from '@wix/members-area-app-definitions';
import { InstallLocation, WidgetId } from '@wix/members-area-app-definitions';

import { MY_ACCOUNT_PAGE_APP_DEF_ID, MY_ACCOUNT_PATH } from './widget';

export {
  COMPONENTS_REF,
  COMPONENTS_SECTION,
  COMPONENTS_SECTION_RESPONSIVE,
  HorizontalMenuRole,
  VERTICAL_LAYOUT_MENU_ROLE,
} from './components';
export { CONTROLLER_PROP_CONFIG_KEY_ROUTES } from './controller';
export { DataHook } from './data-hook';
export { ElementId } from './elements';
export { ViewerPublicApiError } from './errors';
export { Experiment } from './experiments';
export { GfppAction } from './gfpp';
export { HistoryLabel } from './history';
export { MenuId, ViewerMenuId } from './menus';
export {
  SettingsSlotsContainerState,
  SlotsContainerState,
} from './multi-state-box';
export {
  SETTINGS_SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
  SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
} from './slot';
export { MEMBER_SETTINGS_TPA_PAGE_ID, MEMBER_TPA_PAGE_ID } from './tpa';
export { ReferralInfo, SLUG_PLACEHOLDERS, SlugPlaceholder } from './url';
export {
  APP_TOKEN,
  HostWidgetId,
  Layout,
  MY_ACCOUNT_PAGE_APP_DEF_ID,
  MY_ACCOUNT_PAGE_WIDGET_ID,
  MY_ACCOUNT_PATH,
  PROFILE_PAGE_BOB_APP_DEF_ID,
  PROFILE_PAGE_BOB_STAGE_ID,
  PROFILE_PAGE_BOB_WIDGET_ID,
  PROFILE_WIDGET_OPTIONS,
  SANTA_MEMBERS_APP_DEF_ID,
  SETTINGS_PAGE_BOB_WIDGET_ID,
  SETTINGS_WIDGET_OPTIONS,
  SettingsLayout,
  WidgetName,
} from './widget';

export const MY_ACCOUNT_DEFINITION: IntegrationApplication = {
  pageId: 'member_info',
  appDefinitionId: MY_ACCOUNT_PAGE_APP_DEF_ID,
  widgetId: WidgetId.MyAccount,
  urlOverride: MY_ACCOUNT_PATH,
  social: false,
  socialHome: true,
  installLocation: InstallLocation.Settings,
};

export const WARMUP_DATA_STATE_KEY = 'members-warmup-state';

export enum PanelOrigin {
  ProfilePage,
  SettingsPage,
  SinglePageApp,
}

export {
  FOLLOWING_FOLLOWERS_MODAL_WIDGET_ID,
  FOLLOWING_FOLLOWERS_MODAL_WIDGET_OPTIONS,
  LightboxConnectionParam,
} from './lightbox';
