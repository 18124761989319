import { getErrorData } from '@wix/members-area-commons-ts';
import { runInAction } from 'mobx';

import { CTA_ACTION } from '../../../constants/followers';
import type {
  CTAAction,
  FFModalState,
  FollowingFollowersListItem,
  GetResolvedError,
  InitFFModalState,
} from '../../../types/followers';

type HandleErrorOptions = {
  error: unknown;
  state: FFModalState;
  getResolvedError: GetResolvedError;
  enableFFLightboxErrorState: boolean;
};

const getMemberIdListItemIndexInList = (
  memberId: string,
  listItems: FollowingFollowersListItem[],
) => {
  return listItems.findIndex(({ _id }) => _id === memberId);
};

const changeMemberCTAAction = (
  state: FFModalState,
  memberId: string,
  ctaAction: CTAAction,
) => {
  const indexInFollowersList = getMemberIdListItemIndexInList(
    memberId,
    state.followers.members,
  );

  if (indexInFollowersList !== -1) {
    state.followers.members[indexInFollowersList].ctaAction = ctaAction;
  }

  const indexInFollowingList = getMemberIdListItemIndexInList(
    memberId,
    state.following.members,
  );

  if (indexInFollowingList !== -1) {
    state.following.members[indexInFollowingList].ctaAction = ctaAction;
  }
};

const handleError = ({
  error,
  state,
  getResolvedError,
  enableFFLightboxErrorState,
}: HandleErrorOptions) => {
  if (enableFFLightboxErrorState) {
    const { message } = getErrorData(error, getResolvedError);

    state.errorMessage = message;
    state.wrapperMsbState = 'error';
  }
  console.error(error);
};

export const createState: InitFFModalState = ({
  initState,
  lightboxContext,
  currentMemberId,
  enableFFLightboxErrorState,
  services: {
    followersReadService,
    followersWriteService,
    membersService,
    getResolvedError,
  },
}) => {
  const fetchFollowingFollowers = async (memberId: string) => {
    try {
      const response = await followersReadService.getFollowingFollowers(
        memberId,
      );
      state.wrapperMsbState = 'content';
      state.followers = response.followers;
      state.following = response.following;
    } catch (error) {
      handleError({
        error,
        state,
        getResolvedError,
        enableFFLightboxErrorState,
      });
    }
  };

  const fetchCurrentMember = async () => {
    try {
      state.currentMember = await membersService.fetchMyMember();
      state.wrapperMsbState = 'content';
    } catch (error) {
      handleError({
        error,
        state,
        getResolvedError,
        enableFFLightboxErrorState,
      });
    }
  };

  const fetchFollowers = async (memberId: string) => {
    if (state.followers.pagingMetadata?.cursors?.next) {
      const cursor = state.followers.pagingMetadata?.cursors?.next;
      const response = await followersReadService.getFollowers(
        memberId,
        cursor,
      );

      runInAction(() => {
        state.followers.members = [
          ...state.followers.members,
          ...response.members,
        ];
        state.followers.pagingMetadata = {
          count: state.followers.pagingMetadata?.count,
          cursors: response.pagingMetadata?.cursors,
        };
      });
    }
  };

  const fetchFollowing = async (memberId: string) => {
    if (state.following.pagingMetadata?.cursors?.next) {
      const cursor = state.following.pagingMetadata?.cursors?.next;
      const response = await followersReadService.getFollowing(
        memberId,
        cursor,
      );

      runInAction(() => {
        state.following.members = [
          ...state.following.members,
          ...response.members,
        ];
        state.following.pagingMetadata = {
          count: state.following.pagingMetadata?.count,
          cursors: response.pagingMetadata?.cursors,
        };
      });
    }
  };

  const followMember = async (memberId: string) => {
    await followersWriteService.follow(memberId);

    if (lightboxContext?.memberId === currentMemberId) {
      lightboxContext?.onFollowingChange('follow');
    }

    runInAction(() => {
      changeMemberCTAAction(state, memberId, CTA_ACTION.Unfollow);
    });
  };

  const unfollowMember = async (memberId: string) => {
    await followersWriteService.unfollow(memberId);

    if (lightboxContext?.memberId === currentMemberId) {
      lightboxContext?.onFollowingChange('unfollow');
    }

    runInAction(() => {
      changeMemberCTAAction(state, memberId, CTA_ACTION.Follow);
    });
  };

  const { state } = initState<FFModalState>({
    followers: { members: [], pagingMetadata: {} },
    following: { members: [], pagingMetadata: {} },
    currentMember: null,
    wrapperMsbState: 'loading',
    errorMessage: '',
    fetchFollowingFollowers,
    fetchCurrentMember,
    fetchFollowers,
    fetchFollowing,
    followMember,
    unfollowMember,
  });

  return state;
};
