import type {
  SettingsPageContextProps,
  SettingsPageContextServices,
} from '../../../types';
import { fetchCurrentMember } from '../viewer-controller/state';
import { handleNavigation } from './navigation';
import {
  addInitialDataForWidgetPlugins,
  emitMemberChangedEventToPlugins,
} from './widget-plugins';

export const addLoginHandler = (
  contextProps: SettingsPageContextProps,
  contextServices: SettingsPageContextServices,
) => {
  return contextProps.wixCodeApi.user.onLogin(async () => {
    await fetchCurrentMember(contextProps, contextServices);
    addInitialDataForWidgetPlugins(contextProps, contextServices);
    await emitMemberChangedEventToPlugins(contextProps, contextServices);
    await contextServices.multiStateBoxService.showMainAppState();
    await handleNavigation(contextProps, contextServices);
  });
};
