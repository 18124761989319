import type { ContextParams, EditorType } from '@wix/platform-editor-sdk';
import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';

import type { Nullable } from '../../types';
import {
  isConflictError,
  runAndWaitForApproval,
} from '../editor-sdk-wrappers/document';
import { toErrorInstance } from './error-builder';

type DefaultMonitoringParams = {
  metaSiteId?: Nullable<string>;
  userId?: Nullable<string>;
  silentInstallation?: Nullable<boolean>;
  type?: Nullable<EditorType>;
};

type DefaultTags = {
  silentInstallation?: Nullable<boolean>;
  type?: Nullable<EditorType>;
};

type PanoramaClient = Required<EditorScriptFlowAPI>['panoramaClient'];

type ErrorMonitor = ReturnType<PanoramaClient['errorMonitor']>;

type ReportError = ErrorMonitor['reportError'];

type CaptureOptions = Parameters<ErrorMonitor['reportError']>[1] & {
  extra?: any;
};

let panorama: EditorScriptFlowAPI['panoramaClient'];
let errorMonitor: ErrorMonitor | undefined;
let defaultMonitoringParams: DefaultMonitoringParams | undefined;
let defaultTags: DefaultTags | undefined;

const FAILED_INTERACTION_ERROR = 'Failed to start interaction, reason: ';

export const initializeMonitoring = (
  flowAPI: EditorScriptFlowAPI,
  editorContextParams?: Partial<ContextParams>,
) => {
  panorama = flowAPI.panoramaClient;
  errorMonitor = flowAPI.panoramaClient?.errorMonitor();
  const silentInstallation = !!editorContextParams?.silentInstallation;
  const type = editorContextParams?.origin?.type ?? null;

  defaultMonitoringParams = {
    metaSiteId: editorContextParams?.initialAppData?.metaSiteId ?? null,
    userId: editorContextParams?.initialAppData?.userId ?? null,
    silentInstallation,
    type,
  };
  defaultTags = {
    silentInstallation,
    type,
  };
};

const getInteractionOptions = (
  customParams?: Record<string, unknown>,
  defaultParams?: DefaultMonitoringParams,
) => {
  return {
    customParams: {
      ...defaultParams,
      ...customParams,
    },
  };
};

const getCaptureOptions = (
  options: CaptureOptions = {},
  defaultTags: DefaultTags = {},
) => {
  const tags = { ...defaultTags, ...(options?.tags ?? {}) };
  return {
    ...options,
    tags,
  };
};

const reportError: ReportError = (error, data = {}) => {
  const options = getCaptureOptions(data, defaultTags);
  return errorMonitor?.reportError(error, options);
};

const interactionStarted = (
  interactionName: string,
  customParams?: Record<string, unknown>,
) => {
  try {
    const interactionData = getInteractionOptions(
      customParams,
      defaultMonitoringParams,
    );

    panorama?.transaction(interactionName).start(interactionData);
  } catch (e) {
    const err = new Error(FAILED_INTERACTION_ERROR + e);
    reportError(err);
  }
};

const interactionEnded = (
  interactionName: string,
  customParams?: Record<string, unknown>,
) => {
  try {
    const interactionData = getInteractionOptions(
      customParams,
      defaultMonitoringParams,
    );

    panorama?.transaction(interactionName).finish(interactionData);
  } catch (e) {
    const err = new Error(FAILED_INTERACTION_ERROR + e);
    reportError(err);
  }
};

const interactionFailed = (interactionName: string, originalError: unknown) => {
  const error = toErrorInstance(originalError);
  reportError(error, { tags: { interactionName } });
};

export const log = (message: string, data: CaptureOptions = {}) => {
  const options = getCaptureOptions(data, defaultTags);
  const error = new Error(message);

  console.error({ message, options });
  return reportError(error, options);
};

export const toMonitored = async <T>(
  interactionName: string,
  action: () => Promise<T> | T,
  customParams?: Record<string, unknown>,
): Promise<T> => {
  try {
    interactionStarted(interactionName, customParams);
    const response = await action();
    interactionEnded(interactionName, customParams);
    return response;
  } catch (err) {
    interactionFailed(interactionName, err as Error);
    throw err;
  }
};

export const withConflictMonitor = async <T>(
  editorSDK: FlowEditorSDK,
  message: string,
  action: () => Promise<T>,
) => {
  try {
    return await action();
  } catch (e: any) {
    if (await isConflictError(editorSDK, e)) {
      const extra = { tags: { error: e.toString() } };
      log(`DS Conflict error in Members Area: ${message}`, extra);
    }
    throw e;
  }
};

export const transactionWithConflictMonitor = async <T>(
  editorSDK: FlowEditorSDK,
  message: string,
  action: () => Promise<T>,
) =>
  withConflictMonitor(editorSDK, message, () =>
    runAndWaitForApproval(editorSDK, action),
  );

export const monitoredTransactionFactory = (editorSDK: FlowEditorSDK) => {
  const transaction =
    <T extends any[], G>(action: (...args: T) => G) =>
    (...props: T) =>
      runAndWaitForApproval<G>(editorSDK, () => action(...props));

  return <T>(
    name: string,
    action: () => Promise<T>,
    customParams?: Record<string, unknown>,
  ) => {
    return toMonitored(
      name,
      transaction(action),
      customParams,
    ) as unknown as Promise<T>;
  };
};
