import { MembersAreaEvent } from '@wix/members-area-widget-plugin-lib/viewer';

import type {
  Member,
  MembersIdentifiers,
  Nullable,
  SettingsPageContextProps,
  SettingsPageContextServices,
  SettingsPageState,
} from '../../../types';

const getMemberIdentifier = (member: Nullable<Member>) => {
  return member ? { id: member.id!, slug: member.profile?.slug! } : null;
};

const getMembersIdentifiersFromState = (state: SettingsPageState) => {
  const { currentMember } = state;
  const members: MembersIdentifiers = {
    currentMemberIdentifier: getMemberIdentifier(currentMember),
    viewedMemberIdentifier: null,
  };

  return members;
};

export const addInitialDataForWidgetPlugins = (
  { state }: Pick<SettingsPageContextProps, 'state'>,
  {
    widgetPluginHostService,
  }: Pick<SettingsPageContextServices, 'widgetPluginHostService'>,
) => {
  const initialData = getMembersIdentifiersFromState(state);
  return widgetPluginHostService.setMembersData(initialData);
};

export const emitMemberChangedEventToPlugins = (
  { state }: Pick<SettingsPageContextProps, 'state'>,
  {
    widgetPluginHostService,
  }: Pick<SettingsPageContextServices, 'widgetPluginHostService'>,
) => {
  const payload = getMembersIdentifiersFromState(state);
  return widgetPluginHostService.emitEvent({
    type: MembersAreaEvent.MemberChanged,
    payload,
  });
};

export const emitTabChangedEventToPlugins = ({
  widgetPluginHostService,
  routeDataService,
}: Pick<
  SettingsPageContextServices,
  'widgetPluginHostService' | 'routeDataService'
>) => {
  const { visibleWidgetId } = routeDataService.getRouteData();

  return widgetPluginHostService.emitEvent({
    type: MembersAreaEvent.TabChanged,
    payload: visibleWidgetId,
  });
};
