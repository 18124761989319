import {
  getMyMember,
  queryMembers,
} from '@wix/ambassador-members-v1-member/http';
import type { Member } from '@wix/ambassador-members-v1-member/types';
import { Set } from '@wix/ambassador-members-v1-member/types';
import { withResolvedError } from '@wix/members-area-commons-ts';
import type { IHttpClient } from '@wix/yoshi-flow-editor';

import type {
  ErrorHandler,
  MembersService as IMembersService,
  Nullable,
} from '../types';

export class MembersService implements IMembersService {
  constructor(
    private readonly httpClient: IHttpClient,
    private readonly errorHandler: ErrorHandler,
  ) {}

  async fetchCurrentAndViewedMember(
    currentMemberId: Nullable<string>,
    viewedMemberIdOrSlug: Nullable<string>,
  ) {
    if (!currentMemberId && !viewedMemberIdOrSlug) {
      return { currentMember: null, viewedMember: null };
    }

    // This is needed on workspaces because identity returns a different id in that case
    if (currentMemberId === viewedMemberIdOrSlug) {
      const myMember = await this.fetchMyMember();
      return { currentMember: myMember, viewedMember: myMember };
    }

    const [currentMember, viewedMember] = await Promise.all([
      currentMemberId ? this.fetchMyMember() : Promise.resolve(null),
      viewedMemberIdOrSlug
        ? this.fetchViewedMember(viewedMemberIdOrSlug)
        : Promise.resolve(null),
    ]);

    const isViewedMemberCurrentMember = this.isViewedMemberCurrentMember(
      viewedMemberIdOrSlug,
      currentMember,
    );

    return !viewedMember && isViewedMemberCurrentMember
      ? { currentMember, viewedMember: currentMember }
      : { currentMember, viewedMember };
  }

  isViewedMemberCurrentMember(
    viewedMemberIdOrSlug: Nullable<string>,
    currentMember: Nullable<Member>,
  ) {
    return (
      currentMember?.id === viewedMemberIdOrSlug ||
      currentMember?.profile?.slug === viewedMemberIdOrSlug
    );
  }

  async fetchMyMember() {
    const requestFn = () =>
      this.httpClient.request(getMyMember({ fieldSet: Set.FULL }));

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data.member ?? null;
  }

  private async fetchViewedMember(viewedMemberIdOrSlug: string) {
    const filter = {
      $or: [
        { id: viewedMemberIdOrSlug },
        { 'profile.slug': viewedMemberIdOrSlug },
      ],
    };

    const requestFn = () =>
      this.httpClient.request(
        queryMembers({ fieldSet: Set.PUBLIC, query: { filter } }),
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    const [viewedMember] = data.members ?? [];

    return viewedMember ?? null;
  }
}
