import { Experiment } from '../../constants';
import { MembersService } from '../../services/members';
import type { FlowAPI, InitState, Nullable } from '../../types';
import type {
  FFModalContext,
  FFModalContextServices,
  LightboxContext,
} from '../../types/followers';
import { FollowersReadService } from './services/followers-read';
import { FollowersWriteService } from './services/followers-write';
import { createState } from './services/state';

const createServices = (flowAPI: FlowAPI): FFModalContextServices => {
  const { httpClient, errorHandler } = flowAPI;

  const followersReadService = new FollowersReadService(
    httpClient,
    errorHandler,
  );

  const followersWriteService = new FollowersWriteService(
    httpClient,
    errorHandler,
  );

  const membersService = new MembersService(httpClient, errorHandler);

  return {
    followersReadService,
    followersWriteService,
    membersService,
    getResolvedError: errorHandler.getResolvedError,
  };
};

type CreateContextOptions = {
  flowAPI: FlowAPI;
  initState: InitState;
};

export const createContext = ({
  flowAPI,
  initState,
}: CreateContextOptions): FFModalContext => {
  const services = createServices(flowAPI);
  const enableFFLightboxErrorState = flowAPI.experiments.enabled(
    Experiment.EnableFFLightboxErrorState,
  );

  const wixCodeAPI = flowAPI.controllerConfig.wixCodeApi;
  const lightboxContext =
    wixCodeAPI.window.lightbox.getContext() as Nullable<LightboxContext>;

  const currentUser = flowAPI.controllerConfig.wixCodeApi.user.currentUser;
  const currentMemberId = currentUser.loggedIn ? currentUser.id : null;

  const state = createState({
    initState,
    services,
    lightboxContext,
    currentMemberId,
    enableFFLightboxErrorState,
  });

  return { state, lightboxContext, currentMemberId };
};
