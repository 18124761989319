import { withResolvedError } from '@wix/members-area-commons-ts';
import type { IHttpClient } from '@wix/yoshi-flow-editor';

import type { ErrorHandler } from '../../../types';
import type {
  FollowersReadService as IFollowersReadService,
  GetFollowersResponse,
  GetFollowingFollowersResponse,
  GetFollowingResponse,
} from '../../../types/followers';

export class FollowersReadService implements IFollowersReadService {
  constructor(
    private readonly httpClient: IHttpClient,
    private readonly errorHandler: ErrorHandler,
  ) {}

  async getFollowingFollowers(memberId: string) {
    const requestFn = () =>
      this.httpClient.get<GetFollowingFollowersResponse>(
        `/_serverless/following-followers-middleware/${memberId}`,
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }

  async getFollowers(memberId: string, cursor: string) {
    const requestFn = () =>
      this.httpClient.get<GetFollowersResponse>(
        `/_serverless/following-followers-middleware/${memberId}/followers`,
        { params: { cursor } },
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }

  async getFollowing(memberId: string, cursor: string) {
    const requestFn = () =>
      this.httpClient.get<GetFollowingResponse>(
        `/_serverless/following-followers-middleware/${memberId}/following`,
        { params: { cursor } },
      );

    const { data } = await withResolvedError(requestFn, this.errorHandler, {
      errorCodesMap: {},
    });

    return data;
  }
}
