import model from './model';
import { pageReady } from './viewer-controller';
import { getContext } from './viewer-controller/context';

export default model.createController((props) => {
  const { contextProps, contextServices } = getContext(props);

  return {
    pageReady: () => {
      return contextServices.monitoringService.toMonitored(
        'controller.page_ready',
        () => pageReady(contextProps, contextServices),
      );
    },
    exports: {},
  };
});
