import { Experiment, WARMUP_DATA_STATE_KEY } from '../../../constants';
import { getLogEnvironmentTags } from '../../../services/monitoring';
import type {
  ContextProps,
  ContextServices,
  ControllerData,
  InitState,
  Members,
  Nullable,
  RouteConfiguration,
  State,
} from '../../../types';
import type { MonitoringService } from '../../../types/services';

type GetStateProps = {
  initState: InitState;
  contextServices: ContextServices;
  config?: ControllerData;
};

type SerialisableState = {
  routes: State['routes'];
  members: State['members'];
  viewedMemberRoles: State['viewedMemberRoles'];
  isViewedMemberCurrentMember: State['isViewedMemberCurrentMember'];
  membersAreaPagePrefix: State['membersAreaPagePrefix'];
};

const checkControllersRoutesIntegrity = (
  monitoringService: MonitoringService,
  globalRoutes: RouteConfiguration[],
  bobRoutes: RouteConfiguration[],
) => {
  if (globalRoutes.length !== bobRoutes.length) {
    monitoringService.log(
      'Warning: Difference in length between global and BoB controller routes',
      {
        extra: {
          globalRoutes,
          bobRoutes,
        },
      },
    );
    return;
  }

  const mismatchingRoute = globalRoutes.find((route) => {
    const matchingRoute = bobRoutes.find(
      (bobRoute) => bobRoute.widgetId === route.widgetId,
    );
    return !matchingRoute;
  });

  if (mismatchingRoute) {
    monitoringService.log(
      'Warning: Difference in global and BoB controller routes',
      {
        extra: {
          globalRoutes,
          bobRoutes,
        },
      },
    );
  }
};

export const getSerialisableState = (state: State) =>
  JSON.stringify({
    routes: state.routes,
    members: state.members,
    viewedMemberRoles: state.viewedMemberRoles,
    isViewedMemberCurrentMember: state.isViewedMemberCurrentMember,
    membersAreaPagePrefix: state.membersAreaPagePrefix,
  });

export const getState = ({
  initState,
  contextServices,
  config,
}: GetStateProps) => {
  const {
    membersService,
    rolesService,
    pageService,
    flowAPI,
    membersAreaPublicAPI,
    monitoringService,
  } = contextServices;

  let cachedState: SerialisableState | null = null;

  if (!flowAPI.environment.isSSR) {
    try {
      const stateJson = contextServices.warmupDataService.get<string>(
        WARMUP_DATA_STATE_KEY,
      );
      cachedState = stateJson ? JSON.parse(stateJson) : null;
    } catch (e) {
      monitoringService.log(
        'Failed to parse state from warmup data when expected',
        {
          extra: { e: e?.toString() },
        },
      );
    }
  }

  const serialisableState: SerialisableState = cachedState ?? {
    routes: [],
    members: { currentMember: null, viewedMember: null },
    viewedMemberRoles: [],
    isViewedMemberCurrentMember: false,
    membersAreaPagePrefix: '',
  };

  const fetchers = {
    fetchMembers: async (
      currentMemberId: Nullable<string>,
      viewedMemberIdOrSlug: Nullable<string>,
    ) => {
      const members = await membersService.fetchCurrentAndViewedMember(
        currentMemberId,
        viewedMemberIdOrSlug,
      );

      state.members = members;
      state.isViewedMemberCurrentMember =
        membersService.isViewedMemberCurrentMember(
          members.viewedMember?.id!,
          members.currentMember,
        );
    },
    fetchViewedMemberRoles: async () => {
      state.viewedMemberRoles = await rolesService.getMemberRoles(
        state.members.viewedMember?.id ?? null,
      );
    },
    fetchMembersAreaPagePrefix: async () => {
      const membersAreaPagePrefix = await pageService.getProfilePagePrefix();
      state.membersAreaPagePrefix = membersAreaPagePrefix.replace('/', '');
    },
    fetchRouteConfigurations: async () => {
      const { isEditor } = flowAPI.environment;

      const disableRouteIntegrityCheck = flowAPI.experiments.enabled(
        Experiment.DisableRouteIntegrityCheck,
      );

      let routes: RouteConfiguration[] | undefined;

      try {
        routes = await membersAreaPublicAPI.getRoutes();

        if (
          routes?.length &&
          config?.routes?.length &&
          !disableRouteIntegrityCheck
        ) {
          checkControllersRoutesIntegrity(
            monitoringService,
            routes,
            config.routes,
          );
        }

        routes = routes?.length ? routes : config?.routes;
      } catch (e: any) {
        const tags = { error: e.toString(), ...getLogEnvironmentTags(flowAPI) };
        if (!isEditor) {
          monitoringService.log(
            'Warning: global controller or app data routes are missing, fallbacking to BoB controller routes',
            { tags },
          );
        }
        routes = config?.routes;
      }

      if (!isEditor && (!routes || routes.length === 0)) {
        monitoringService.log(
          'Error: routes are missing in both data sources',
          { tags: getLogEnvironmentTags(flowAPI) },
        );
      }

      state.routes = routes || [];
    },
  };

  const { state } = initState<State>({
    ...serialisableState,
    ...fetchers,
  });

  return state;
};

const getIsCurrentMemberChanged = (
  members: Members,
  currentMemberId: Nullable<string>,
) => {
  return currentMemberId !== members.currentMember?.id;
};

const getIsViewedMemberChanged = (
  members: Members,
  viewedMemberSlugOrId: Nullable<string>,
) => {
  const viewedMemberId = members.viewedMember?.id;
  const viewerMemberSlug = members.viewedMember?.profile?.slug;

  return !(
    viewerMemberSlug === viewedMemberSlugOrId ||
    viewedMemberId === viewedMemberSlugOrId
  );
};

export const fetchViewedAndCurrentMember = async (
  { state, flowAPI }: Pick<ContextProps, 'state' | 'wixCodeApi' | 'flowAPI'>,
  {
    currentUserService,
    routeDataService,
  }: Pick<ContextServices, 'currentUserService' | 'routeDataService'>,
) => {
  const { environment } = flowAPI;
  const { members, routes, fetchMembers, fetchViewedMemberRoles } = state;

  // Avoiding refetch in the editor - ids always differ in workspaces when using identity
  if (environment.isEditor && members.currentMember && members.viewedMember) {
    return { hasMemberChanged: false };
  }

  const { slugOrId } = routeDataService.getRouteData();
  const currentMemberId = currentUserService.getCurrentUserId();
  const isCurrentMemberChanged = getIsCurrentMemberChanged(
    members,
    currentMemberId,
  );
  const isViewedMemberChanged = getIsViewedMemberChanged(members, slugOrId);
  const hasMemberChanged = isViewedMemberChanged || isCurrentMemberChanged;

  if (hasMemberChanged) {
    await fetchMembers(currentMemberId, slugOrId);
  }

  const hasRestrictedRoutes = routes.some(
    (route) => (route?.vfr?.length ?? 0) > 0,
  );

  if (hasMemberChanged && hasRestrictedRoutes) {
    await fetchViewedMemberRoles();
  }

  return { hasMemberChanged };
};

export const fetchInitialMemberPageData = async ({
  state,
}: Pick<ContextProps, 'state'>) => {
  const {
    fetchRouteConfigurations,
    fetchMembersAreaPagePrefix,
    routes,
    membersAreaPagePrefix,
  } = state;

  const shouldFetchRouteConfigurations = !routes || routes.length <= 0;
  const fetchRouteConfigurationsPromise = shouldFetchRouteConfigurations
    ? fetchRouteConfigurations()
    : Promise.resolve();

  const shouldFetchMembersAreaPagePrefix = !membersAreaPagePrefix;
  const fetchMembersAreaPagePrefixPromise = shouldFetchMembersAreaPagePrefix
    ? fetchMembersAreaPagePrefix()
    : Promise.resolve();

  return Promise.all([
    fetchRouteConfigurationsPromise,
    fetchMembersAreaPagePrefixPromise,
  ]);
};
