import {
  SettingsSlotsContainerState,
  SlotsContainerState,
} from './multi-state-box';

enum SlotRoleId {
  S1 = 's1',
  S2 = 's2',
  S3 = 's3',
  S4 = 's4',
  S5 = 's5',
  S6 = 's6',
  S7 = 's7',
  S8 = 's8',
  S9 = 's9',
  S10 = 's10',
  S11 = 's11',
  S12 = 's12',
  S13 = 's13',
  S14 = 's14',
  S15 = 's15',
  S16 = 's16',
  S17 = 's17',
  S18 = 's18',
  S19 = 's19',
  S20 = 's20',
}

export const SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE: Record<
  string,
  SlotsContainerState
> = {
  [SlotRoleId.S1]: SlotsContainerState.B1,
  [SlotRoleId.S2]: SlotsContainerState.B2,
  [SlotRoleId.S3]: SlotsContainerState.B3,
  [SlotRoleId.S4]: SlotsContainerState.B4,
  [SlotRoleId.S5]: SlotsContainerState.B5,
  [SlotRoleId.S6]: SlotsContainerState.B6,
  [SlotRoleId.S7]: SlotsContainerState.B7,
  [SlotRoleId.S8]: SlotsContainerState.B8,
  [SlotRoleId.S9]: SlotsContainerState.B9,
  [SlotRoleId.S10]: SlotsContainerState.B10,
  [SlotRoleId.S11]: SlotsContainerState.B11,
  [SlotRoleId.S12]: SlotsContainerState.B12,
  [SlotRoleId.S13]: SlotsContainerState.B13,
  [SlotRoleId.S14]: SlotsContainerState.B14,
  [SlotRoleId.S15]: SlotsContainerState.B15,
  [SlotRoleId.S16]: SlotsContainerState.B16,
  [SlotRoleId.S17]: SlotsContainerState.B17,
  [SlotRoleId.S18]: SlotsContainerState.B18,
  [SlotRoleId.S19]: SlotsContainerState.B19,
  [SlotRoleId.S20]: SlotsContainerState.B20,
};

enum SettingsSlotRoleId {
  S1 = 's1',
  S2 = 's2',
  S3 = 's3',
  S4 = 's4',
  S5 = 's5',
  S6 = 's6',
  S7 = 's7',
  S8 = 's8',
}

export const SETTINGS_SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE: Record<
  string,
  SettingsSlotsContainerState
> = {
  [SettingsSlotRoleId.S1]: SettingsSlotsContainerState.B1,
  [SettingsSlotRoleId.S2]: SettingsSlotsContainerState.B2,
  [SettingsSlotRoleId.S3]: SettingsSlotsContainerState.B3,
  [SettingsSlotRoleId.S4]: SettingsSlotsContainerState.B4,
  [SettingsSlotRoleId.S5]: SettingsSlotsContainerState.B5,
  [SettingsSlotRoleId.S6]: SettingsSlotsContainerState.B6,
  [SettingsSlotRoleId.S7]: SettingsSlotsContainerState.B7,
  [SettingsSlotRoleId.S8]: SettingsSlotsContainerState.B8,
};
