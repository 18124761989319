import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import demoFollowerSRC1 from '../../../../assets/images/demo-follower-1.png';
import demoFollowerSRC2 from '../../../../assets/images/demo-follower-2.png';
import { CTA_ACTION } from '../../../../constants/followers';
import type { FollowingFollowersListItem } from '../../../../types/followers';

export const getDemoFollowersFollowing = (
  t: PlatformControllerFlowAPI['translations']['t'],
): FollowingFollowersListItem[] => {
  return [
    {
      _id: '1',
      avatar: demoFollowerSRC1,
      nickname: t('app.fflist.demo-member-nickname.1'),
      ctaAction: CTA_ACTION.Follow,
    },
    {
      _id: '2',
      avatar: demoFollowerSRC2,
      nickname: t('app.fflist.demo-member-nickname.2'),
      ctaAction: CTA_ACTION.Unfollow,
    },
  ];
};
