import type { WidgetId } from '@wix/members-area-app-definitions';
import type {
  IMembersAreaWidgetPlugin,
  MembersAreaHostEvent,
} from '@wix/members-area-widget-plugin-lib/viewer';

import type {
  MembersIdentifiers,
  RouteDataService,
  SettingsPageW,
  WidgetPluginHostService as IWidgetPluginHostService,
} from '../../../types';

type WidgetPlugin =
  | (IMembersAreaWidgetPlugin & { type: WidgetId })
  | { [p: string]: any; isMethodSupported: (methodName: string) => boolean };

export class WidgetPluginHostService implements IWidgetPluginHostService {
  constructor(
    private readonly $w: SettingsPageW,
    private readonly routeDataService: RouteDataService,
  ) {}

  setInitialData() {
    const widgetPlugins = this.getWidgetPlugins();
    widgetPlugins.forEach((plugin) => {
      plugin.isWidgetPlugin = true;
    });
  }

  setMembersData(initialData: MembersIdentifiers) {
    const widgetPlugins = this.getWidgetPlugins();

    widgetPlugins.forEach((widgetPlugin) => {
      widgetPlugin.members = initialData;
    });
  }

  async membersAreaWidgetReady(): Promise<void> {
    const { visibleWidgetId } = this.routeDataService.getRouteData();
    const widgetPlugins = this.getWidgetPlugins();
    const widgetPlugin = widgetPlugins.find(
      (plugin) => plugin.type === visibleWidgetId && !plugin.isRendered,
    );

    this.setVisibleWidgetIdForAll(visibleWidgetId, widgetPlugins);

    if (!widgetPlugin) {
      return Promise.resolve();
    }

    widgetPlugin.isRendered = true;

    return widgetPlugin.membersAreaWidgetReady();
  }

  emitEvent(event: MembersAreaHostEvent) {
    const widgetPlugins = this.getWidgetPlugins();

    widgetPlugins.forEach((widgetPlugin) => {
      widgetPlugin.onMembersAreaEvent(event);
    });
  }

  private setVisibleWidgetIdForAll(
    visibleWidgetId: WidgetId,
    widgetPlugins: WidgetPlugin[],
  ) {
    widgetPlugins.forEach((plugin) => {
      plugin.visibleWidgetId = visibleWidgetId;
    });
  }

  private getWidgetPlugins() {
    const multiStateBoxStates = this.$w('#appsContainer').children ?? [];

    const widgetPluginsInMSB = multiStateBoxStates.reduce<WidgetPlugin[]>(
      (widgetPlugins, multiStateBoxState) => {
        const [statePlaceholder] = multiStateBoxState.children;

        return statePlaceholder?.slot
          ? [...widgetPlugins, statePlaceholder.slot]
          : widgetPlugins;
      },
      [],
    );

    return widgetPluginsInMSB;
  }
}
